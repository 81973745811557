import { instance } from '~/api/instance'
import { ResourceRequestParams } from '~/utils/api'

export default class LocalizationApi {
  static async getList(resource: string, params: ResourceRequestParams) {
    return await instance.post(`${resource}/list`, {...params})
  }

  static async getOne(resource: string, id: number) {
    return await instance.get(`${resource}/${id}`)
  }

  static async update(resource: string, {id, value}: {id: number, value: string}) {
    return await instance.patch(`${resource}/${id}`, {value})
  }
}
