import {
  DatagridConfigurable, Edit, EditButton, FilterButton, FilterForm, Labeled,
  List,
  Pagination, SelectColumnsButton, SimpleForm,
  TextField, TextInput,
} from 'react-admin'
import { Stack } from '@mui/material'
import React from 'react'

export const LocalizationProperties = () => {
  return (
    <List pagination={<PostPagination />}>
      <ListToolbar />
      <DatagridConfigurable>
        <TextField label='ID' source='id' />
        <TextField label='ID значения' source='propertyValueId' />
        <TextField label='Перевод' source='value' />
        <EditButton />
      </DatagridConfigurable>
    </List>
  )
}

export const LocalizationPropertiesEdit = () => {
  return (
    <Edit title='Изменение перевода'>
      <SimpleForm>
        <Labeled label='id перевода'>
          <TextInput source='id' readOnly />
        </Labeled>
        <Labeled label='id свойства'>
          <TextInput source='propertyValueId' readOnly />
        </Labeled>
        <Labeled label='id языка'>
          <TextInput source='languageId' readOnly />
        </Labeled>
        <Labeled label='Перевод'>
          <TextInput source='value' />
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}

const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const ListToolbar = () => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ ml: 2, mr: 2 }}
    >
      <FilterForm filters={postFilters} />
      <div>
        <SelectColumnsButton />
        <FilterButton filters={postFilters} />
      </div>
    </Stack>
  )
}

const postFilters = [
  <TextInput label="ID свойства" source="propertyValueId" defaultValue="" />,
]
