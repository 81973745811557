import { DataProvider, UpdateParams } from 'react-admin'
import { Response } from '~/utils/types'
import { parserParamsForGetList } from '~/utils/dataProviderParams'
import LocalizationApi from '~/api/localization-api'

type UpdateLocalization = {
  id: number;
  languageId: number;
  propertyValueId: number;
  value: string;
}

export const localizationDataProvider: DataProvider = {
  async getList(resource: string, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await LocalizationApi.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },

  async getOne(resource: string, {id}: {id: number}) {
    return await LocalizationApi.getOne(resource, id)
  },

  async update(resource: string, params: UpdateParams<UpdateLocalization>) {
    if (params.data.value === null)
      params.data.value = ''
    return await LocalizationApi.update(resource, {id: params.data.id, value: params.data.value})
  }
}
